.hidden {
  display: none !important;
}

body {
  font-family: "Nunito Sans", sans-serif;
  font-family: "Heebo", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: unset;
}

.AppContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;

  grid-template-columns: 256px 1fr 350px;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "logo menu account"
    "sidebar content content";
}

.AppContainer.-Survey {
  grid-template-areas:
    "logo menu account"
    "content content content";
}

.AppContainer.-Vetting {
  grid-template-columns: 250px 1fr 350px;
  grid-template-areas:
    "logo menu account"
    "sidebar content content";
}

.AppLogo {
  grid-area: logo;
  background-color: white;
  border-bottom: $app-grid-border;
}

.AppMenu {
  background-color: white;
  grid-area: menu;
  border-bottom: $app-grid-border;
}

.AppAccount {
  grid-area: account;
  border-bottom: $app-grid-border;
}

.AppSidebar {
  grid-area: sidebar;
  background-color: white;
  border-right: $app-grid-border;
  padding-top: 50px;
}

.AppSidebar__Vetting {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
}

.AppContent {
  grid-area: content;
  background-color: $app-content-background;
  min-width: 800px;
  overflow-y: auto;
}

.SurveyProgress {
  // border: 1px solid red;
  width: 100%;
  height: 55px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SurveyForm {
  padding-top: 0px;
  width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.SurveyFormWider {
  padding-top: 20px;
  width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.InputContainer {
  width: 300px;
}

.InputContainerWide {
  width: 400px;
}

.InputContainerWider {
  width: 450px;
}

.Label {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 5px;
  color: #333;
  display: block;
}

.StepContainerIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
}

.StepContainerDivider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
}

.StepDivider {
  width: 30px;
  height: 1px;
  background-color: #c4c4c4;
  margin-bottom: 10px;
}

.StepContainerDivider.completed .StepDivider {
  width: 30px;
  height: 1px;
  background-color: $color-primary;
}

.StepIcon {
  width: 20px;
  height: 20px;

  margin-bottom: 4px;

  stroke: #c4c4c4;

  svg {
    width: 20px;
    height: 20px;

    stroke: #c4c4c4;
  }
}

.StepIconFill {
  fill: #c4c4c4;

  svg {
    fill: #c4c4c4;
  }
}

.StepIconStroke {
  stroke: #c4c4c4;

  svg {
    stroke: #c4c4c4;
  }
}

.StepContainerIcon.active .StepIcon {
  stroke: $color-primary;
  svg {
    stroke: $color-primary !important;
  }
}

.StepContainerIcon.active .StepIconFill {
  fill: $color-primary;
  svg {
    fill: $color-primary !important;
  }
}

.StepContainerIcon.completed .StepIconFill {
  fill: $color-primary;
  svg {
    fill: $color-primary;
  }
}

.StepContainerIcon.active .StepIconStroke {
  stroke: $color-primary;
  svg {
    stroke: $color-primary !important;
  }
}

.StepContainerIcon.completed .StepIconStroke {
  stroke: $color-primary;
  svg {
    stroke: $color-primary;
  }
}

.StepContainerIcon.active .StepText {
  color: $color-primary;
  font-weight: 600;
}

.StepContainerIcon.completed .StepText {
  color: $color-primary;
  font-weight: 600;
}

.StepText {
  color: #c4c4c4;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  font-style: normal;
}

.SurveyCard {
  background-color: white;
  width: 1054px;
  height: 510px;
  // min-height: 200px;
  border-radius: 10px;
  //overflow-y: auto;
}

.SurveyButtonContainer {
  margin-top: 0;
  margin-bottom: 30px;
}

.SurveyButtonContainer--Centered {
  margin-top: 70px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Button {
  width: 200px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: Montserrat;
  line-height: 22px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;

  &:active {
    border-style: none;
  }
}

.ButtonSmaller {
  width: 200px;
  height: 40px;
}

.ButtonAdd {
  color: white;
  background-color: $color-primary;
  width: 100px;
  height: 40px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0;
}

.ButtonAdd.Disabled {
  background-color: #f2f2f2;
  color: #bdbdbd;
  width: 100px;
  text-transform: capitalize;
  cursor: default;
}

.Question {
  margin-bottom: 26px;
}

.ButtonPrimary {
  color: white;
  background-color: $color-primary;
}

.ButtonBack {
  background-color: #f2f2f2;
  color: #bdbdbd;
  margin-right: 30px;
}

.SocialConnectHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #767889;
  margin-bottom: 20px;
}

.SocialConnectList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SocialConnectItem {
  width: 150px;
  height: 150px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.SocialConnectItem__Logo {
  height: 30px;
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.SocialConnectItem__Text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  color: #767889;
}

.SocialPopup {
  position: fixed;
  width: 350px;
  height: 240px;

  background: #ffffff;
  border: 1px solid #eceff6;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  top: calc(350px);
  left: calc(50% - 175px);
}

.InputContainerPopupSocial {
  margin-bottom: 20px;
  width: 240px;
}

.SocialConnectedSuccess {
  color: $color-success;
  font-weight: bold;
}

.ProfilePicture img {
  width: 150px;
  height: 150px;
  border: 2px solid $color-primary;
  border-radius: 50%;
  box-sizing: border-box;
}

.ProfilePicture .empty {
  width: 150px;
  height: 150px;
  background-color: white;
  border: 2px solid $color-primary;
  border-radius: 50%;
  box-sizing: border-box;
}

.ProfilePicture {
  margin-bottom: 30px;
}

.ProfilePictureUploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.DropZoneButton {
  height: 50px;
  width: 220px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 0 20px 0 20px;
  background-color: $color-success;
  color: #ffffff;
}

.SurveyFinished {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.SurveyFinished .Ilustration {
  margin-top: 50px;
  width: 220px;
  height: 220px;
}

.Input {
  width: 100%;
  font-size: 12px;
  // color: #6f6f6f;
  letter-spacing: 0.3px;
  height: 40px;
  padding-left: 20px;
  font-weight: 400;
  color: #333;
  border: "#eceff6 1px solid";

  background: #ffffff;
  border: 1px solid #eceff6;
  box-sizing: border-box;
  border-radius: 5px;

  outline: none;

  &:focus {
    border-color: $color-primary;
  }

  &::placeholder {
    color: #bbb;
  }

  &:disabled {
    background-color: $color-disabled-form;
    color: #999;
  }
}

.InputWeeklyRate {
  padding-left: 70px;

  &::placeholder {
    color: #555 !important;
  }
}

.InputWeeklyRateIconContainer {
  position: relative;
  width: 0;
  height: 0;
}

.InputWeeklyRateIcon {
  height: 40px;
  width: 40px;
  background: rgba(47, 128, 237, 0.1);
  color: $color-primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  //top: 50px;
  border-radius: 5px 0 0 5px;
}

.react-datepicker-wrapper {
  width: 250px;
}

// Vetting

.VettingSidebar {
}

.VettingRequestMoreTime {
}

.VettingProgressList {
  margin-left: 30px;
  user-select: none;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
}

.VettingProgressSeperator {
  height: 40px;
  margin: 15px 0;
  border-left: #c4c4c4 1px solid;
  margin-left: 16px;
}

.VettingProgressItem {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;

  width: 250px;
  height: 30px;

  margin: 0;
  padding: 0;

  padding-left: 10px;

  &__Icon {
    width: 17px;
    height: 17px;

    svg {
      width: 17px;
      height: 17px;
      fill: #c4c4c4;
    }
  }

  &__Name {
    margin-left: 25px;
    font-size: 11px;
    text-transform: uppercase;
    color: #c4c4c4;
    width: 100px;
    line-height: 1;
    margin-top: 9px;
  }

  &__Step {
    background-color: #eae9e9;
    color: white;
    width: 24px;
    height: 24px;

    font-size: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    margin-left: 198px;
    margin-top: 9px;
    position: absolute;
  }
}

.VettingProgressItem.active .VettingProgressItem__Icon svg {
  //fill: $color-primary;
  stroke: $color-primary;
}

.VettingProgressItem .VettingProgressItem__Icon svg {
  //fill: $color-primary;
  fill: none;
  stroke: gray;
}

.VettingProgressItem.active .VettingProgressItem__Name {
  color: $color-primary;
  font-weight: 500;
}

.VettingProgressItem.active .VettingProgressItem__Step {
  background-color: $color-primary;
}

.VettingProgressItem__StepCheck {
  width: 15px;
  height: 15px;
}

.VettingProgressSeperator.active {
  border-color: $color-primary;
}

.VettingProgressItem .active {
  &__Icon {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
      fill: $color-primary;
    }
  }

  &__Name {
    margin-left: 25px;
    font-size: 14px;
    text-transform: uppercase;
    color: #c4c4c4;
    width: 220px;
  }

  &__Step {
    background-color: #eae9e9;
    color: white;
    width: 30px;
    height: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    margin-left: 284px;

    position: absolute;
  }
}

// Profile Pictures

.ProfilePictureContainer {
  width: 100%;
  height: 100%;
}

.ProfilePictureContainer img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ProfilePictureName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #74b9ff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 800;
  color: #0984e3;
  border: rgba(255, 255, 255, 0.8) 1px solid;
}

.SurveyUsersList {
  display: flex;
  flex-direction: column;

  span {
    margin-left: 20px;
  }
}

.SurveyUsersList__Item {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  background-color: white;
  padding: 15px 20px;
  width: 500px;
  margin-bottom: 10px;

  &:hover {
    background-color: #e4f1fe;
  }
}

.SurveyUsersList__ItemPictureContainer {
  width: 50px;
  height: 50px;
}

.SurveyUsersList__ItemName {
  margin-left: 10px;
}

.Badge {
  padding: 5px 10px;
  border-radius: 8px;

  background-color: $color-primary;
  color: white;
  font-weight: 600;
}

.CardSizeRegular {
  width: 400px;
}

.ContentContainerPadded {
  padding: 10px;
}

.FormSection {
  margin-bottom: 20px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .FormGroup {
    margin-bottom: 20px;
  }
}

.CardList {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.CardItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-bottom: 10px;
  min-height: 50px;
  padding: 10px;
}

.CardItemCell {
  width: 280px;
  padding: 10px;
}

.CardItemField {
  margin: 0 10px;
  font-size: 16px;
}

.CardItemHeader {
  width: 280px;
  padding: 10px;
  font-weight: bold;
}

.DataList {
  display: flex;
  flex-direction: column;
}

.DataItem {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.DataItemLabel {
  font-weight: bold;
  font-size: 13px;
}

.DataItemValue {
  color: $color-primary;
  font-weight: bold;
  font-size: 16px;
}

.PrimaryText {
  color: $color-primary;
  font-size: 14px;
  font-weight: bold;
  word-spacing: 8px;
}

// Vetting page

.VettingPageContainer {
  padding-top: 80px;
  padding-left: 140px;
}

.VettingMessage {
  width: 850px;
  height: 60px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);
  padding: 0 20px;
  margin-bottom: 70px;

  &.--closer {
    margin-bottom: 20px;
  }

  &.--smallerbox {
    width: 713px;
    height: unset;
    padding: 20px 10px;
  }

  &.--flexible {
    height: auto;
  }

  .VettingMessageSuccess {
    font-size: 12px;
    font-weight: 600;
    color: $color-success;
  }

  .VettingMessageGray {
    font-size: 12px;
    font-weight: 300;
    color: #444;
    padding-left: 6px;
  }

  .VettingMessageGrayBigger {
    font-size: 14px;
    font-weight: 300;
    color: #555;
    padding-left: 6px;
  }

  .VettingMessageDefault {
    font-size: 12px;
    font-weight: normal;
    color: black;
  }
}

.VettingMessageShort {
  width: 700px;
}

.LabelOptional {
  font-size: 10px;
  color: #898989;
}

.VettingCoderbyteExtensionCount {
  margin: 0;
  padding: 0;
  margin-left: auto;
  font-size: 11px;
  color: #898989;
  width: 65px;
}

.VettingCoderbyteExtensionMessage {
  color: #898989;
  font-size: 10px;
  margin: 0 10px;
  margin-top: -9px;
}

.VettingSidebarLogoutContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 80px;
  bottom: 0;
  margin: auto auto 60px auto;

  .VettingSidebarLogout__Icon {
    width: 22px;
    height: 22px;
    svg {
      stroke: #999;
    }
  }

  .VettingSidebarLogout__Text {
    color: #999;
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
    height: 15px;
  }

  &:hover {
    .VettingSidebarLogout__Icon {
      svg {
        stroke: $color-primary;
      }
    }
    .VettingSidebarLogout__Text {
      color: $color-primary;
    }
  }
}

.VettingProfileCard {
  width: 850px;
  min-height: 205px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);

  display: flex;
  flex-direction: row;
}

.VettingProfileCardShort {
  width: 740px;
  height: 218px;
}

.VettingProfileCardLeftContent {
  width: 61%;
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: center;
  //padding-top: 50px;
  margin: 20px 0;
  border-right: #e0e0e0 1px solid;
  padding-left: 0px;
}

.PictureContainer {
  width: 135px;
  height: 135px;
  margin-left: 35px;
  margin-right: 7px;
}

.VettingProfileDivider {
  background-color: red;
  height: 120px;
  width: 30px;
}

.InfoContainer {
  display: flex;
  flex-direction: column;
  width: 330px;
  margin-left: 20px;

  h1 {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 23px;
    color: #3e4449;
    width: 100%;
    overflow: hidden;
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;


    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 29px;

    margin-bottom: 4px;

    color: #3E4449;
  }

  p {
    padding: 0;
    margin: 0;

    font-size: 11px;
    color: #828282;
    padding-top: 0px;
    line-height: 1;
  }

  span {
    font-size: 10px;
    color: #3e4449;
    padding-top: 30px;
  }

  .SkillList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 8px;
    width: 100%;

    .SkillListItem {
      background-color: #2f80ed;
      color: #6f6f6f;
      border-radius: 5px;
      padding: 4px 18px;
      margin: 0px 5px 4px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }

      height: 28px;
      padding: 4px 15px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
      font-weight: normal;
      font-size: 10px;

      .SkillListItemYears {
        margin-left: 7px;
        border: 1px solid white;
        border-radius: 50%;
        width: 12px;
        height: 12px;

        padding: 8px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        text-transform: lowecase;
        font-weight: 700;
        font-size: 8px;
        //background-color: white;
        color: white;
      }
    }
  }
}

.VettingProfileCardRightContent {
  // padding-top: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //padding-left: 10px;
  width: 32%;
  margin: 0 auto;
}

.VettingInfoItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.PlaceholderPicture {
  background-color: #ddd;
  border: #ccc 1px solid;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & .PlaceholderPicture--text {
    font-size: 10px;
    color: #666;
    display: none;
  }

  & .PlaceholderPicture--icon {
    color: #aaa;
    width: 50px;
    height: 50px;
  }

  &:hover .PlaceholderPicture--text {
    display: block;
  }

  &:hover .PlaceholderPicture--icon {
    display: none;
  }
}

.VettingInfoItemLabel {
  font-size: 12px;
  color: #3e4449;
  width: 100px;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;

  color: #5C6371;

  svg {
    margin-right: 10px;
  }
}

.VettingInfoItemValue {
  font-size: 12px;
  color: #828282;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  //height: 20px;
  margin-left: 10px;
}

.VettingInfoItemSocial {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SocialItem {
  margin-right: 14px;

  img {
    height: 20px;
    //width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }
}

.SocialItemGithub img {
  height: 16px;
}

.SocialItemLinkedin img {
  height: 14px;
}

.SocialItemTwitter img {
  height: 10px;
}

.BasicProfilePictureContainer {
  width: 80px;
  height: 80px;
}

.InfoCard {
  width: 1200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.CardTitle {
  color: $color-primary;
  font-size: 22px;
  font-weight: 600;
  padding: 15px 20px;
}

.CardRow {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;

  div {
    margin: 0 10px;
  }
}

.CardRowPushedRight {
  margin-left: auto !important;
  display: flex;
  flex-direction: row;
}

.ButtonSuccess {
  background-color: $color-success;
  color: white;
}

.ButtonDanger {
  background-color: $color-danger;
  color: white;
}

.VettingCodeChallengeContentContainer {
  display: flex;
  flex-direction: column;
}

.VettingCodeChallengeEstimation {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;

  font-size: 12px;
  color: #888;
}

.VettingCodeChallengeType {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 220px;
}

.VettingCodeChallengeIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(47, 128, 237, 0.2);
  margin-right: 15px;
  margin-left: 30px;
  display: flex;
  flex-display: row;
  justify-content: center;
  align-items: center;

  svg {
    color: $color-primary;
  }
}

.VettingCodeChallengeText {
  margin-top: 25px;
  margin-left: 6px;
  width: 396px;
}

.VettingCodeChallengeTextHeader {
  font-size: 32px;
  color: #3e4449;
  font-weight: bold;
  margin: 0;
  padding: 0;
  line-height: 22px;
}

.VettingCodeChallengeTextRole {
  font-size: 14px;
  color: #767889;
  padding: 0;
  margin: 0;
  line-height: 35px;
  padding-left: 2px;
}

.VettingCodeChallengeTextTimeLeft {
  color: $color-primary;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 4px;
    font-size: 12px;
  }
}

.VettingCodeChallengeTextTimeAdd {
  color: #898989;
  letter-spacing: 0.1px;
  font-size: 9px !important;
  text-decoration: underline;
  margin-left: 8px !important;
  cursor: pointer;

  &.-no-link {
    cursor: default;
    text-decoration: none;
  }
}

.VettingCodeChallengeTextTimeAddIcon {
  width: 12px;
}

.VettingCodeChallengeLinkContainer {
  //margin-left: 82px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-top: 36px;
  text-decoration: none !important;

  &:hover {
    text-decoration: none;
  }

  span {
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.VettingChallengeCard {
  background-color: white;
  display: inline-flex;
  border-radius: 2%;
}

.VettingCodeChallengeLink {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  background-color: $color-primary;
  color: white !important;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;

  &.--disabled {
    background-color: lighten($color-primary, 25%);
    color: white;
    cursor: default;
  }
}

.VettingCodeChallengeCallIconContainer {
  margin-left: -33px;
  margin-right: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  margin-top: 6px;
}

.AppCard__PopupButtons {
  display: flex;
  flex-direction: row;

  justify-content: flex-end;

  border-top: 1px solid $color-whisper;
  padding-top: 20px;

  button {
    margin-left: 10px;
  }
}

.AppCard__CoderbyteExtensionRequest {
  min-width: 400px;
  //   padding: 10px 20px;
  padding: 0 !important;

  Form {
    padding: 20px;
  }
}

.VettingCodeChallengeCallIcon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(0, 205, 176, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #00ad95;
  font-weight: bold;
}

.VettingCodeChallengeCallIconIcon {
  color: #00ad95;
  stroke-width: 1.5;
  width: 20px;
}

.VettingSuccess {
  width: 550px;
  height: 580px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-left: 23px;
}

.VettingSuccessHeader {
  background-color: $color-primary;
  height: 100px;
  border-radius: 8px 8px 0 0;
  margin: 0;
  padding-top: 75px;
}

.VettingSuccessHeaderIcon {
  background-color: white;

  border-radius: 50%;
  position: relative;
  margin-left: 225px;
}

.VettingSuccessBody {
  background-color: white;
  height: 400px;
}

.VettingFailedContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.VettingFailedMessageContainer {
  padding: 40px;
}

.VervoeChallengeScheduledAt {
  color: $color-primary;
  font-size: 10px;
}

.VettingVervoeStartInterviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.VettingVideoIcon {
  stroke: $color-primary;
}
.VettingSuccessIconContainer {
  background-color: white;
  width: 130px;
  height: 130px;
  position: relative;
  bottom: 70px;
  border: #eee 1px solid;
  border-radius: 50%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    width: 60px;
    height: 60px;
    stroke: black;
  }
}

.VettingVervoeStartInterviewTimeLeft {
  font-size: 10px;
  margin-top: 8px;
  color: #777;
  height: 12px;
}

.VettingCodeChallengeIconImage {
  color: $color-success;
  width: 50px;
  height: 50px;
}
.VettingSuccessBodyText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 30px;
    color: #3e4449;
    margin-bottom: 30px;
    margin-top: -30px;
  }

  p {
    width: 430px;
    font-size: 14px;
    color: #828282;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 10px;
  }

  button {
    margin-top: 20px;
  }
}

.PageHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 15px;
  margin-bottom: 50px;
  margin-top: 20px;

  padding-bottom: 1px;
  border-bottom: darken($color-whisper, 22%) 1px solid;

  h1 {
    font-size: 1.8rem;
    color: #222;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 0.7rem;
    color: #222;
    flex: end;
    margin-left: auto;
  }
}

// Form profile edit

.ncard {
  width: 860px;
  background-color: white;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-left: 30px;
  border-radius: 10px;
}

.ncard-icon {
  // border: 1px solid;

  width: 18px;
  height: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
}

.ncard-header {
  // background-color: $color-primary;
  color: $color-primary;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;

  // width: 965px;

  padding-bottom: 0px;
  border-bottom: 1px solid $color-whisper;

  h2 {
    margin-left: 4px;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;

    margin: 0 0 0 5px;
    width: 88%;
  }

  .arrow {
    width: 20px;
    height: 20px;
    fill: white;
    margin-left: auto;
    margin-right: 20px;
  }

  &.--danger {
    background-color: $color-danger;
  }
}

.ncard-content {
  border-radius: 0 0 6px 6px;
  padding: 20px 30px;
}

.form-label {
  font-size: 14px;
  color: #858585;
  margin-bottom: 6px;

  &.--no-bottom-margin {
    margin-bottom: 0px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.form-section-halfwidth {
  width: 50%;
  padding: 0 15px 0 15px;
  margin-bottom: 20px;
}

.form-section-halfwidth-hoz {
  width: 70%;
  padding: 0 15px 0 15px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-items: center;

  label {
    width: 150px;
    text-align: right;
    margin-right: 40px;
  }
}

.form-section-fullwidth {
  width: 100%;
  padding: 0 15px 0 15px;
  margin-bottom: 20px;
}

.form-section-70width {
  width: 70%;
  margin: 15px;
}

.form-section-30width {
  width: 30%;
  margin: 15px;
}

.toggle-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 13px;
}

.toggle-label {
  margin-left: 5px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-section-31width {
  width: 31%;
  margin: 15px;
}

.form-section-80width {
  width: 80%;
  margin: 15px;
}

.form-section-20width {
  width: 20%;
  margin: 15px;
}

.form-section-10width {
  width: 10%;
  margin: 15px;
}

.profile-edit-skill-item-edit,
.profile-edit-skill-item-view {
  padding: 7px;
  // border-bottom: 1px solid #eceff6;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile-edit-empty-list-checkbox-container {
  margin-left: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  label {
    margin-left: 6px;
    text-align: right;
    margin-bottom: 0px !important;
    font-size: 12px;
    color: #888;
  }
}

.form-button-delete {
  font-size: 12px;
  color: #a1b4e0;
  background-color: #eceff6;
  border-radius: 3px;
  box-shadow: none;
  border: none;
  width: 80px;
  height: 34px;
}

.form-section-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.form-label-horizontal {
  margin: 0;
  margin: 0 0 0 10px;
}

.form-button-delete-for-skill {
  margin-top: 37px;
}

.form-button-add {
  background-color: $color-primary;
  border: none;
  color: white;
  font-size: 12px;
  padding: 12px 20px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 10px;
}

.form-button-add-container {
  margin-left: auto;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.form-button-save {
  color: white;
  background-color: $color-success;
  border: none;
  color: white;
  font-size: 12px;
  padding: 12px 20px;
  font-weight: bold;
  border-radius: 5px;
}

.form-button-save-container {
  margin-left: auto;
  width: 120px;
  margin-bottom: 30px;
}

.list-empty-info {
  width: 100%;
  height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #bbb;
  font-size: 18px;
  font-weight: bold;
  border: 1px dashed #bbb;
}

.form-company-logo-container {
  width: 50px;
  height: 50px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
}

.form-company-logo {
  width: 50px;
  height: 50px;

  img {
    width: 50px;
    height: 50px;
  }

  .form-company-logo-empty {
    width: 50px;
    height: 50px;
    background-color: #f6f8fa;
    border: 1px solid $color-whisper;
    margin-top: 22px;
  }

  .form-company-logo-found {
    margin-top: 10px;
  }
}

.form-company-logo.--form img {
  margin-top: 9px;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.profile-edit-item {
  border-bottom: 1px solid $color-whisper;
}

.form-button-add-line {
  background-color: $color-primary;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.portfolio-upper-part {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.portfolio-image-empty {
  width: 200px;
  height: 200px;
  background-color: #f6f8fa;
  border: 1px solid $color-whisper;
}

.portfolio-image-container {
  width: 564px;
  height: 240px;
  //margin-right: 40px;
  //border: 1px solid $color-whisper;

  // margin-bottom: 20px;

  margin-right: 20px;

  img {
    width: 280px;
    height: 224px;
  }
}

.portfolio-image-container.--smaller {
  width: 250px;
  height: 168px;

  img {
    width: 196px;
    height: 168px;
  }
}

.form-section-line-fixer {
  width: 100%;
  margin-top: 20px;
  display: flex;
}

.form-section-textbox-with-toggle {
}

.toggle-under-textbox-container {
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
}

.ProfileUploadForm-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
}

.ProfileUploadForm-Image {
  width: 150px;
  height: 150px;
  margin-right: 40px;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    background-color: #eceff6;
  }
}

.SkillItem__ViewItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  min-height: 50px;
}

.SkillItem__ViewItem__Logo {
  // width: 50px;
  height: 50px;
}

.SkillItem__ViewItem_Content__Header {
  padding-left: 20px;

  display: flex;
  flex-direction: row;
}

.SkillItem__ViewItem_Content__Header__Name {
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }

  span {
    font-size: 13px;
    color: #727374;
    margin: 0;
    padding-top: 0px;
  }
}

.SkillItem__ViewItem_Content__Description {
  font-size: 11px;
  line-height: 18px;
  color: #828282;
  padding-left: 20px;
  padding-top: 0px;
  width: 670px;
}

.MiniSkillList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 20px;
  margin-top: 8px;
}

.MiniSkillItem {
  margin-right: 5px;
  background-color: #136afb;
  color: white;
  border-radius: 3px;
  padding: 3px 6px;
  font-size: 11px;
  margin-bottom: 10px;
}

.SkillItem__ViewItem_Content__Header__EditButton {
  width: 24px;
  height: 24px;
  //background-color: $color-primary;
  margin-left: 20px;
  border-radius: 50%;
  //margin-top: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    padding-top: 2px;
    stroke: #a1b4e0;
    margin-bottom: 2px;
    margin-left: 1px;
  }
}

.SkillItem__ViewItem__Remove {
  margin-left: auto;
}

.JobLine__Add {
  background-color: $color-primary;
  border-radius: 50%;

  color: white;

  width: 24px;
  height: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.SkillItem__ViewItem__Remove span {
  //background-color: #eceff6;
  color: #a1b4e0;
  padding: 4px 7px;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
  }
}

.EditCloseButton {
  background-color: $color-primary;
  color: white;
  padding: 9px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;

  &.--green {
    background-color: $color-success;
    margin-left: 20px;
    width: 85px;
  }

  &.--item-save {
    margin-left: auto;
  }
}

.JobLines {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

.JobLine {
  display: flex;
  flex-direction: row;
  justify-self: center;
  width: 100%;
  margin-bottom: 10px;
}

.JobLine__Text {
  width: 100%;
}

.JobLinesContainer {
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
}

.JobLine__Buttons {
  width: 40px;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.JobLine__Buttons__Remove {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eceff6;
  color: #a1b4e0;
  border: none;
  padding: 10px;
  margin-left: 40px;
}

.JobLine__Buttons__Add {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  color: white;
  border: none;
  padding: 10px;
  margin-left: 40px;
}

.JobItem__ViewItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  min-height: 50px;
  padding-bottom: 20px;
}

.JobItem__ViewItem__Logo {
}

.JobItem__ViewItem__Header {
  display: flex;
  flex-direction: row;
}

.JobItem__ViewItem__Header__Text {
  display: flex;
  flex-direction: column;
}

.JobItem__View_Item__Header__Text__Role {
  padding-left: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: black;
}

.JobItem__View_Item__Header__Text__Company {
  padding-left: 20px;
  font-size: 12px;
  color: #3e4449;
  line-height: 22px;
}

.JobItem__View_Item__Header__Text__Period {
  margin-left: 10px;
  font-size: 12px;
  line-height: 22px;
  color: #828282;
}

.JobItem__View_Item__Header__Text__City {
  font-size: 12px;
  line-height: 22px;
  color: #828282;
  padding-left: 20px;
}

.JobItem__View_Item__Header__Text__Country {
  font-size: 12px;
  line-height: 22px;
  color: #828282;
}

.JobItem__View_Item__Description {
  padding-left: 20px;
}
.JobItem__View_Item__Line {
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.JobItem__ViewItem__Header__Container {
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
}

.add-job-button-container {
  width: 138px;
}

.JobItem__View_Item__Header__Text__CompanyPeriod {
  height: 19px;
}

.Education__ViewItem {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.Education__ViewItem_Text {
  display: flex;
  flex-direction: column;
}

.Education__ViewItem_Institution {
  font-weight: 500;
  font-size: 16px;
}

.Education__ViewItem_Degree {
  font-weight: 500;
  font-size: 13px;
}

.Education__ViewItem_Period {
  font-size: 13px;
  color: #727374;
}

.ProjectItem__ViewItem {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  padding-bottom: 25px;
}

.PreferenceLabel {
  font-size: 16px;
  margin-bottom: 0;
}

.PreferenceLabelInfo {
  font-size: 11px;
  margin: 0;
  padding: 0;
  color: #999;
}

.ProjectItem__ViewItem__Logo {
  width: 200px;
  height: 200px;

  img {
    width: 200px;
    height: 200px;
    border: $color-whisper 1px solid;
  }
}

.ProjectItem__ViewItem__Text {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 80%;
}

.ProjectItem__ViewItem__Header {
  display: flex;
  flex-direction: row;
}

.ProjectItem__ViewItem__Header__NameRole {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.ProjectItem__ViewItem__Name {
  font-weight: 600;
  font-size: 16px;
  color: black;
  line-height: 22px;
}

.ProjectItem__ViewItem__Role {
  font-size: 12px;
  color: $color-primary;
  line-height: 22px;
}

.form-label-blurb {
  font-size: 11px;
  margin: 0;
  margin-top: 5px;
  padding: 0;
  color: #999;
  margin-bottom: 10px;
}

.ProjectItem__ViewItem__Description {
  font-size: 12px;
  font-weight: 300;
  color: #828282;
  line-height: 18px;
  padding-left: 20px;
  margin-top: 10px;
}

.ProjectItem__ViewItem__DeleteButton {
  margin-left: auto;

  span {
    background-color: #eceff6;
    color: #a1b4e0;
    padding: 4px 7px;
    font-size: 12px;
    border-radius: 2px;
    cursor: pointer;
  }
}

.AppContentArea {
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  overflow-y: hidden;

  margin: 0;
  padding: 0;
}

.AppContentHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
  margin-top: 20px;
  user-select: none;
  padding-bottom: 1px;
  border-bottom: darken($color-whisper, 22%) 1px solid;

  h1 {
    font-size: 1.8rem;
    color: #222;
    padding: 0;
    margin: 0;
    margin-left: 10px;
  }

  p {
    font-size: 0.7rem;
    color: #222;
    flex: end;
    margin-left: auto;
  }
}

.AppCard {
  background-color: white;
  display: block;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.AppCard--medium {
  width: 850px;
}

.AppCard__Header {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin: 20px;
}

.AppCard--fixed {
  height: 300px;
  overflow-y: scroll;
}

.AppButton {
  background-color: white;
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.--primary {
    background-color: $color-primary;
    color: white;

    &:disabled {
      background-color: lighten($color-primary, 25%);
    }
  }

  &.--large {
    padding: 10px 20px;
    font-size: 14px;
    min-width: 100px;
  }

  &.--vettingFinish {
    padding: 14px 20px;
    font-size: 16px;
    min-width: 120px;
    width: 180px;
    height: 50px;
    font-weight: bold;
  }

  &.--success {
    background-color: $color-success;
    color: white;

    &:hover {
      background-color: darken($color-success, 5%);
    }

    &:disabled {
      background-color: #6f6f6f;
    }
  }

  &.--cancel {
    background-color: unset;
    border: #444 1px solid;
    color: #444;
  }

  &.--danger {
    background-color: $color-danger;
    color: white;
  }
}

.AppPaddedSection {
  margin: 8px;
}

.FullscreenPopupContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(127, 127, 127, 0.6);
  z-index: 10;
}

.PopupContainer {
  min-width: 200px;
  min-height: 200px;
  z-index: 100;
}

.AppCard {
  border-radius: 5px;
}

.AppCard_DaySchedule {
  width: 500px;
}

.CardHeader {
  width: 100%;
  height: 30px;

  border-bottom: $color-whisper 1px solid;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  h2 {
    font-size: 16px;
    margin: 0;
    padding: 0 5px;
  }
}

.CardFooter {
  width: 100%;
  min-height: 30px;

  border-top: $color-whisper 1px solid;
}

.CardContent {
  max-height: 500px;
  overflow-y: auto;
}

.CommonFormContainer {
  width: 700px;
}

.FormGroup {
  width: 100%;
  padding: 5px 10px;
  margin: 10px 0;
}

.InputSmall {
  width: 320xp;
}

.--flexrow {
  display: flex;
  flex-direction: row;

  * {
    margin: 0 10px;
  }
}

.CardFooterButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 15px;

  * {
    margin-left: 10px;
  }
}

.InvitationOptions {
  // background-color: blue;
  margin-left: 40px;
  border-left: #ccc 2px solid;
  padding: 10 10px;
}

.InvitationOptionsHeader {
  color: #888;
  font-size: 12px;
  margin-left: 6px;
}

.ItemTableContainer {
  min-width: 600px;
  min-height: 300px;
}

.SimpleTable {
  thead {
    background-color: #f2f2f2;

    td {
      font-size: 12px;
      font-weight: bold;
    }
  }

  tbody {
    padding: 5px;

    tr {
      height: 20px;

      td {
        min-width: 120px;
        height: 40px;
        //  display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: $color-whisper 1px solid;
        padding: 2px 15px;

        &.TableTinyFont {
          font-size: 11px;
        }
      }
    }
  }
}

.SurveyTip {
  font-size: 12px;
  margin-top: 5px;
  color: #999;
  font-style: italic;
}

.DateTimePickerWidth {
  width: 300px;
  width: 100%;
  font-size: 14px;
  // color: #6f6f6f;
  letter-spacing: 0.3px;
  padding-left: 20px;
  font-weight: 500;
  color: #333;
  border: "#eceff6 1px solid";

  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0 5px 5px 0;
  height: 40px;
  border: none;
  outline: none;

  &:disabled {
    background-color: $color-disabled-form;
    color: #999;
  }
}

.QuestionLabelInfo {
  font-size: 11px;
  margin: 0;
  padding: 0;
  color: #999;
}

.SocialQuestionContainer {
  margin-bottom: 40px;

  .SocialIcon {
    height: 40px;
    width: auto;
  }
}

.SurveyEmailVerificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 480px;

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 18px;
    font-size: 14px;
    width: 100%;

    .TextPrimary {
      color: $color-primary;
    }
  }
}

.SurveyFinishContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;

  h1 {
    text-align: center;
    margin-bottom: 6px;
  }

  p {
    margin-bottom: 10px;
    font-size: 14px;

    .TextPrimary {
      color: $color-primary;
    }
  }
}

.SurveyFinishDividor {
  width: 100%;
  height: 1px;
  margin: 18px 0;
  background-color: $color-primary;
}

.SurveyFinishStep {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    width: 94%;
  }
}

.SurveyFinishStepBullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $color-primary;
  margin-right: 12px;
}

.SurveyFinishButtonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.SurveyEmailVerificationIcon {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;

  svg {
    width: 70px;
    height: 70px;
  }
}

.svgStroke {
  color: red;
  fill: red;
  stroke: red;
}

.LinkHoverable {
  cursor: pointer;
}

.SurveyWaitlistContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 490px;

  h1 {
    font-size: 22px;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
  }

  h2 {
    margin-top: 10px;
    font-size: 20px;
  }
}

.SurveyWaitlistApplyDate {
  font-weight: bold;
}

.FastTrackButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.FastTrackRemainingTimeLabel {
  font-size: 12px !important;
  color: #999;
  margin-top: 4px;
}

.SocialGrayout {
  filter: saturate(0%) brightness(70%) contrast(1000%);
}

.SocialIconContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.SocialIconAdd {
  color: $color-primary;
  font-size: 10px;
  margin-left: 6px;
  font-weight: 500;
  cursor: pointer;
}

.VettingInfoIcon {
  font-size: 12px;
  color: #3e4449;
  width: 14px;
  margin-right: 4px;
}

.SocialIconCheckmark {
  width: 12px;
  height: 12px;
  padding: 2px;
  margin-left: 4px;
  background-color: $color-success;
  color: white;
  border-radius: 50%;

}

.ProgressBarRemainingText.--danger {
  color: $color-danger;
}

.TableCellIcon-Checkmark {
  text-align: center;
  svg {
    stroke: $color-primary;
  }
}

// Table Styles
.nDataTable {
  padding: 8px;
  margin: 20px;
  font-size: 13px;
  thead {
    color: #bbb;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    tr {
      th {
        padding: 2px;
        text-align: left;
        padding-right: 20px;
      }
    }
  }

  tbody {
    tr {
      background-color: white;
      height: 40px;
      margin-top: 8px;

      td {
        padding-left: 2px;
        padding-right: 18px;
      }
    }

    tr.spacer {
      height: 10px;
    }
  }
}

.TableEmailField {
  font-weight: bold;
  color: #666;
}

.TableRowExpander {
}

.TableCreatedAtElement {
  display: flex;
  flex-direction: column;

  .TableCreatedAtElement__When {
    font-size: 12px;
    padding: 0;
    margin: 0;
    line-height: 1;
  }

  .TableCreatedAtElement__From {
    font-size: 10px;
    color: #bbb;
    padding: 0;
    margin: 0;
    line-height: 1;
    margin-top: 4px;
  }
}

.AlertCircle--attension {
  margin-left: 3px;
  stroke: $color-primary;
  // animation: AlertCircleAnimation 5.1s infinite;
}

@keyframes AlertCircleAnimation {
  0% {
    stroke: $color-primary;
  }
  50% {
    stroke: lighten($color-primary, 20%);
  }
  100% {
    stroke: $color-primary;
  }
}

.AppContentBodyPadded {
  padding: 10px;
}

.FieldDetailsContainer {
  display: flex;
  flex-display: row;
  flex-wrap: wrap;
  padding: 5px 10px;
}

.FieldDetails__Item {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.FieldDetails__Item__Label {
  font-size: 11px;
  color: #888;
  margin-bottom: 4px;
  line-height: 1;
}

.FieldDetails__Item__Value {
  font-size: 14px;
  margin: 0;
  line-height: 1;
}

.FieldDetailsDivider {
  width: 100%;
  height: 20px;
  border-top: $color-whisper 1px solid;
}

.TextEmptyLabel {
  color: #aaa;
  font-size: 12px;
  font-style: italic;
}

.VettingEventsContainer {
  display: flex;
  flex-direction: column;
}

.VettingEventItem {
  display: flex;
  flex-direction: row;
  span {
    width: 33%;
  }
}

.VettingEventItem__Type {
  color: black;
  font-weight: bold;
}

.VettingEventItem__FromNow {
  color: #888;
  font-style: italic;
}

.ChallengeLinkIcon {
  width: 18px;
  padding-bottom: 2px;
}

.CoderbyteSkillSelect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CoderbyteSkillSelect__Item {
  width: 140px;
  height: 40px;
  font-size: 15px;
}

.AppCard__VettingSkillSelect {
  padding: 20px;

  h3 {
    margin-bottom: 20px;
  }
}

.InputContainerFixed {
  position: fixed;
}

.VettingMessageIcon {
  width: 40px;
  margin-bottom: auto;
  width: 22px;
  margin-bottom: auto;
  margin-right: 5px;
}

.VettingPreferenceContainer {
  margin-top: 0px;
  margin-left: 60px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.VettingPreferenceCard {
  width: 1000px;
  padding: 40px 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.VettingPreference__Role {
  width: 100%;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
}

.VettingPreference__Rate {
  width: 50%;
  height: 230px;
}

.VettingPreference__Schedule {
  margin-top: 10px;
  width: 100%;
  min-height: 150px;
}

.VettingCurrentTimezoneInfo {
  margin: 0;
  margin-top: -12px;
  margin-left: 10px;
  margin-bottom: 6px;
  font-size: 10px;
  color: #888;
}

.TagCheck {
  width: 12px;
  height: 12px;
}

.profile-editor-item-controlls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button:disabled {
    background-color: lighten($color-primary, 25%);
    color: white;
    cursor: default;
  }

  &.--push-down {
    margin-top: 30px;
    margin-left: auto;
  }
}

.SimpleToggle.react-toggle--checked .react-toggle-thumb {
  border-color: $color-primary;
}

.SimpleToggle.react-toggle--checked .react-toggle-track {
  background-color: $color-primary;
}

.SimpleToggle.react-toggle--checked:hover .react-toggle-track:hover {
  background-color: darken($color-primary, 10%);
}

.SimpleToggle:hover.react-toggle--checked:hover .react-toggle-track {
  background-color: darken($color-primary, 10%);
}

.CustomerSurveyPhoneInput {
  background-color: red;
}