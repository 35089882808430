//Colors

$app-content-background: #f6f8fa;
//$color-primary: #2f80ed;
$color-primary: #136afb;
$color-success: #00cdb0;
$color-danger: #e74c3c;
$color-whisper: #eaecf4;

$color-disabled-form: hsl(0, 0%, 95%);

$app-grid-border: #eceff6 1px solid;

$app-content-width: 1024px;


// Layouts

.AppContentContainer {
  width: $app-content-width;
  display: flex;
  flex-direction: column;

  overflow: hidden;

  margin-left: 20px;
  margin-top: 20px;

  width: 1200px;
}

.App {
  overflow: hidden;
}

.AppContentContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
}

// Sidebar

$sidebar-left-margin: 40px;
$sidebar-link-height: 35px;
$sidebar-link-icon-dim: 14px;
$sidebar-disabled-color: #bbb;

.SidebarLink__Row {
  height: $sidebar-link-height;
}

.SidebarLink {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  text-decoration: none;

  margin: 0 $sidebar-left-margin/2;
  padding-left: $sidebar-left-margin/2;

  &:hover {
    text-decoration: none;
  }

  &.active {
    background-color: #edf4fc;
    border-radius: 5px;
  }
}

.SidebarLink__Icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  height: 100%;
  width: auto;

  svg {
    width: $sidebar-link-icon-dim;
    height: $sidebar-link-icon-dim;
    color: #999;
  }
}

.SidebarLink.active .SidebarLink__Icon svg {
  color: $color-primary;
}

.SidebarLink__Title {
  line-height: 1;
  text-decoration: none;
  margin-left: $sidebar-left-margin/2;

  font-size: 14px;
  font-weight: 600;
  color: #222;
  font-family: "Nunito Sans";
  font-family: Heebo;
  font-family: "Open Sans";

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.SidebarLink__Title.--HelpLink {
  font-size: 12px;
  color: #666;
}

.SidebarLink.active .SidebarLink__Title {
  color: $color-primary;
}

.SidebarLink.--disabled .SidebarLink__Title {
  color: $sidebar-disabled-color;
  user-select: none;
}

.SidebarLink.--disabled .SidebarLink__Icon svg {
  color: $sidebar-disabled-color;
}

.SidebarGroup {
  height: auto;
}

.SidebarGroup__TitleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  cursor: pointer;
  margin-left: $sidebar-left-margin;
}

.SidebarGroup__Title {
  font-size: 13px;
  text-transform: uppercase;
  color: #222;
  font-weight: 300;
}

.SidebarGroup__Chevron {
  margin-left: 10px;

  svg {
    width: 14px;
    height: 14px;
  }
}

.SidebarGroup__TitleRow.--disabled .SidebarGroup__Title {
  color: $sidebar-disabled-color;
}
.SidebarGroup__TitleRow.--disabled .SidebarGroup__Chevron {
  color: $sidebar-disabled-color;
}

// Cards

.Card {
  background-color: white;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  border: 1px solid $color-whisper;
  border-radius: 4px;

  &.--primary {
    background-color: #e3f2fd;
    color: white;
    border: 1px solid $color-primary;
  }
}

.CardHeader {
  border-bottom: 1px solid $color-whisper;
  min-height: 50px;

  .CardHeader__Text {
    font-size: 20px;
    height: 100%;

    padding: 0;
    margin: 0;

    margin-left: 10px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  &.--success {
    background-color: $color-success;
    color: white;
  }

  &.--danger {
    background-color: $color-danger;
    color: white;
  }

  &.--primary {
    background-color: $color-primary;
    color: white;
  }
}

.CardInfoMessage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  p {
    max-width: 400px;
    font-size: 14px;
    color: #666;
  }
}

// Buttons

// Components

.InfoLineContainer {
  margin: 5px;

  .InfoLine {
    margin-bottom: 4px;
  }
}

.InfoLine {
  label {
    width: 120px;
    font-weight: bold;
    margin: 0;
  }

  .Value {
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
}
